<template>
  <div
    class="d-flex w-100 align-items-center"
    :style="`justify-content:${pro.style.authInputPosition}`"
    @input="handleOtpInput"
  >
      <input
        v-model="data[index - 1]"
        :ref="setFirstInputEl"
        type="text"
        maxlength="1"
        v-for="index in fields"
      :class="`mr-3`"
      :key="index"
        class="text-dark font-weight-medium date-input CustomTextInput text-center m-1 otpInput"
       
        :style="customOtpInputStyles(pro.style) + borderStyle(pro.style.borderType)"
        @paste="index === 1 && handlePaste($event)"
      />
  </div>
</template>

<script>
import cssFunction from "../customize/mixin/cssFunction";
export default {
  mixins: [cssFunction],
  props: ["pro"],
  data() {
    return {
      fields: 5,
      data: [],
      firstInputEl: null,
    };
  },
  methods: {
    borderStyle(property){
        return  property === 'border'
          ? this.getInputBorderCSS(this.pro.style)
          : this.getInputBorderBottomCSS(this.pro.style)
    },
    getInputBorderCSS(property) {
      return (
        "border:" +
        property.borderSize +
        "px solid " +
        property.strokeColor +
        "; border-radius:" +
        property.borderRadius +
        "px; " +
        "transform: rotate(-" +
        property.strokeRotation +
        "deg);"
      );
    },
    getInputBorderBottomCSS(property) {
      return (
        "border-bottom:" +
        property.borderSize +
        "px solid " +
        property.strokeColor +
        "; border-radius:" +
        property.borderRadius +
        "px; " +
        "transform: rotate(-" +
        property.strokeRotation +
        "deg);"
      );
    },
    setFirstInputEl(el) {
      if (!this.firstInputEl) this.firstInputEl = el;
    },
    handleOtpInput(e) {
      if (e.data && e.target.nextElementSibling) {
        e.target.nextElementSibling.focus();
      } else if (e.data == null && e.target.previousElementSibling) {
        e.target.previousElementSibling.focus();
      }
    },
    handlePaste(e) {
      const pasteData = e.clipboardData.getData("text");
      this.$nextTick(() => {
        let nextEl = this.firstInputEl.nextElementSibling;
        for (let i = 1; i < pasteData.length; i++) {
          if (nextEl) {
            this.data[i] = pasteData[i];
            nextEl = nextEl.nextElementSibling;
          }
        }
      });
    },
    updateModelValue() {
      if (
        this.data != "" &&
        this.data.length === this.fields &&
        !this.data.includes("")
      ) {
        this.$emit('updateOtpValue', Number(this.data.join("")));
      } else {
          this.$emit('updateOtpValue', null);
      }
    },
    customOtpInputStyles(property) {
      return ` --inputPlaceholderColor:${property.strokeColor} ; --inputColor:${
        property.strokeColor
      } ; text-align:${property.textInputAlign} ; opacity: ${
        property?.textInputOpacity || 100
      }%;border:none;padding:10px 0px;height:${property.authInputHeight}px;width:${property.authInputWidth}px;`;
    },
  },
 
  watch: {
    data: {
      handler() {
        this.updateModelValue();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
/* .otpInput{
    background-color: transparent;
  border: none;
  outline: none;
}
.otpInput:focus{
    outline: 0;
    border: 0 !important;
    border-color: transparent !important;
} */
.CustomTextInput {
  color: var(--inputColor) !important;
}
.date-input {
  background-color: transparent;
  border: none;
  outline: none;
}
</style>
